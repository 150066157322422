import { useState, useEffect } from "react";

import {
    Flex,
    GridItem,
    Heading,
    Text
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import useFetchCreditPurchasePlan from "@/hooks/useFetchCreditPurchasePlan";
import useCreateInAppOrderMutation from "@/hooks/mutations/useCreateInAppOrderMutation";
import { CreditPlanItem as CreditPlanItemInterface } from "@/utils/interfaces/creditPlan";

import CreditPlanList from "./CreditPlanList";
import CreditPlanItem from "./CreditPlanItem";
import CreditPlanContactUsItem from "./CreditPlanContatUsItem";
import CreditPlanSkeletonLoading from "./CreditPlanSkeletonLoading";


interface CreditPlanProps {

}

const CreditPlan = (props: CreditPlanProps) => {
    // const creditPlanItems=[
    //     {planName: "Plan1", revenue: 1500, credits: 1500, amountPerCredit: 1.00},
    //     {planName: "Plan2", revenue: 2500, credits: 2625, amountPerCredit: 0.95},
    //     {planName: "Plan3", revenue: 4000, credits: 4400, amountPerCredit: 0.91},
    //     // {planName: "Custom", revenue: 0, credits: 0, amountPerCredit: 0.00},
    // ];
    // useState
    const [currentCreditPlan, setCurrentCreditPlan] = useState<string | null>(null);
    const [creditPlanItems, setCreditPlanItems] = useState<CreditPlanItemInterface[]>([]);

    // hooks
    const creditPurchaseQuery = useFetchCreditPurchasePlan(true, {
        onSuccess: (data: any) => {
            let creditPurchasePlans = [];
            for (let i = 0; i < data.length; i++) {
                creditPurchasePlans.push({
                    planName: data[i]["plan"],
                    revenue: data[i]["revenue"],
                    credits: data[i]["credits_to_add"],
                    amountPerCredit: data[i]["amount_per_credit"]
                })
            }
            setCreditPlanItems(creditPurchasePlans);
        }
    });

    const createInAppOrderMutation = useCreateInAppOrderMutation({
        onSuccess: (data: any) => {
            if ("Cashfree" in window) {
                console.log("Cashfree is available...");
                const mode: string = import.meta.env.VITE_CASHFREE_ENVIRONMENT || "sandbox";
                const cashfree = window.Cashfree({
                    mode: mode
                });
                cashfree.checkout({
                    paymentSessionId: data.data.cf_payment_session_id
                });
            }else {
                console.log("Error: Cashfree is not available");
            }
        }
    });

    // methods and handlers
    const isCreditPlanDisabled = (planName: string) => {
        return createInAppOrderMutation.isLoading && currentCreditPlan !== planName;
    }

    const isCurrentCreditPlanBuyButtonLoading = (planName: string) => {
        return createInAppOrderMutation.isLoading && currentCreditPlan === planName;
    }

    const handleOnBuyButtonClick = (amount: number, creditsToAdd: number, planName: string) => {
        setCurrentCreditPlan(planName);
        createInAppOrderMutation.mutate({ "amount": amount, "credits_to_add": creditsToAdd, "plan": planName });
    }

    return (
        <Flex
            id="creditplan"
            flexDirection="column">
            <Heading
                size="lg"
                color="blue.dark"
                textAlign="center"
                mb="0.5rem">Buy more credits. Get more customers</Heading>
            <Text 
                as="span"
                textAlign="center"
                color="gray.textPrimary"
                mb="3rem">
                Buy credits and spend them only when you use them.
                Credits do not expire!
            </Text>

            {/* Shows skeleton loading */}
            {creditPurchaseQuery.isLoading && <CreditPlanSkeletonLoading />}
            
            {!creditPurchaseQuery.isLoading &&
                <CreditPlanList>
                    {creditPlanItems?.map(plan => {
                        return (
                            <GridItem>
                                <CreditPlanItem 
                                    planName={plan.planName}
                                    revenue={plan.revenue}
                                    credits={plan.credits}
                                    amountPerCredit={plan.amountPerCredit}
                                    isBuyButtonLoading={isCurrentCreditPlanBuyButtonLoading(plan.planName)}
                                    isDisabled={isCreditPlanDisabled(plan.planName)}
                                    onBuyButtonClick={handleOnBuyButtonClick} />
                            </GridItem>
                        )
                    })}
                    <CreditPlanContactUsItem isDisabled={isCreditPlanDisabled("Custom")} />
                </CreditPlanList>}
        </Flex>
    );
}



export default CreditPlan;